<template>
  <div class="popup" :class="className">
    <div class="popup__overlay">
      <div class="ly--content--container">
        <div class="popup__wrapper">
          <div class="popup__header">
            <img
              src="/images/common/logo_8alance.png"
              alt=""
              class="popup__logo"
            />
            <icon
              name="icon_cross"
              @click="handleClose"
              class="popup__close"
              v-if="!hideClose"
            ></icon>
          </div>
          <div class="popup__content">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { icon } from "~/components/common";
import { useCommonStore } from "~/src/stores/common";
import { configHTMLClass, lastPopupFunc } from "~/src/utils/";

export default {
  name: "popup",
  props: {
    className: { type: String, default: "" },
    hideClose: { type: Boolean, default: false },
  },
  setup() {
    const commonStore = useCommonStore();

    return { commonStore };
  },
  components: { icon },
  mounted() {
    configHTMLClass({ method: "add" });
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
  beforeUnmount() {
    lastPopupFunc();
  },
};
</script>

<style lang="scss">
@import "~/assets/style/include/_commonInclude.scss";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;

  &__wrapper {
    background: color(white);
    border-radius: rem(8);
    padding: rem(24) rem(18);
    max-height: 80vh;
    position: fixed;
    width: rem(358);
    max-width: calc(100% - 32px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    display: flex;
    flex-direction: column;
  }

  &__content {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    width: rem(120);
  }

  &.no--close {
    .icons {
      display: none;
    }
  }
}
</style>
